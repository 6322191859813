import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef } from 'react';
import { Button } from '../../../components/Footer/Input/styles';
import Input from './Input';
import {
    Container,
    Description,
    ForgotPassword,
    ForgotTheCode,
    InfoText,
    InputContent,
    InputTitle,
    SubTitleContent,
    Title,
    TitleInfo,
    UnderlinedTitle,
} from './styles';

const Tracking: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    return (
        <Container>
            <Title>Rastreamento de objetos</Title>
            <UnderlinedTitle />
            <SubTitleContent>
                <InfoText>
                    Através desta opção você poderá rastrear os seus objetos que
                    foram enviados, utilizando a plataforma Mande Seguro.
                </InfoText>
            </SubTitleContent>
            <Form
                ref={formRef}
                onSubmit={() => {
                    // eslint-disable-next-line no-console
                    console.log('submit');
                }}
            >
                <InputTitle>Informe o código de rastreamento</InputTitle>
                <InputContent>
                    <Input
                        name="code"
                        placeholder="DIGITE AQUI O CÓDIGO DE RASTREAMENTO"
                    />
                    <Button
                        style={{
                            width: '389px',
                            height: '50px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                        }}
                    >
                        PROSSEGUIR
                    </Button>
                </InputContent>
                <ForgotTheCode>Não me recordo do código</ForgotTheCode>
                <TitleInfo style={{ marginTop: '36px' }}>
                    Informações sobre o código de rastreamento
                </TitleInfo>
                <UnderlinedTitle style={{ marginTop: '10px' }} />
                <TitleInfo style={{ marginTop: '23px' }}>
                    O código é composto
                </TitleInfo>
                <Description style={{ marginTop: '16px' }}>
                    Por números e letras e o mesmo é informado na etiqueta de
                    rastreamento, mesma que é colada no objeto para verificar o
                    código novamente caso tenha esquecido é necessário consultar seus
                    últimos envios através do seu login.
                </Description>
                <Description style={{ marginTop: '25px' }}>
                    Digite seu login e senha abaixo que iremos redirecionar para a
                    área do cliente
                </Description>
                <InputContent style={{ marginTop: '16px' }}>
                    <Input
                        name="email"
                        placeholder="Digite o seu e-mail"
                        label="E-mail"
                    />
                    <Input
                        name="password"
                        placeholder="Digite sua senha de acesso"
                        label="Senha"
                    />
                </InputContent>
                <Button
                    style={{
                        marginTop: '28px',
                        width: '389px',
                        height: '50px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                    }}
                >
                    PROSSEGUIR
                </Button>
            </Form>
            <ForgotPassword>Esqueci minha senha</ForgotPassword>
        </Container>
    );
};

export default Tracking;
