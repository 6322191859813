import React from 'react';
import { useTransition } from 'react-spring';

import Toast from './Toast';
import { ToastMessage } from '../../hooks/toast';
import { Container } from './styles';

interface ToastContainerProps {
    messages: Array<ToastMessage>;
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
    const messagesWithTransictions = useTransition(
        messages,
        (message: ToastMessage) => message.id,
        {
            from: { top: '-120%', opacity: 0, transform: 'rotateZ(0deg)' },
            enter: { right: '0%', opacity: 1 },
            // enter: { right: '0%', opacity: 1, transform: 'rotateZ(360deg)' },
            leave: { right: '-120%', opacity: 0 },
        },
    );

    return (
        <Container>
            {messagesWithTransictions.map(({ item, key, props }) => (
                <Toast key={key} style={props} message={item} />
            ))}
        </Container>
    );
};

export default ToastContainer;
