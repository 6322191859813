import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import TermoDeUso from '../pages/TermoDeUso';
import PoliticaDePrivacidade from '../pages/PoliticaDePrivacidade';
import Home from '../pages/Home';

const Routes: React.FC = () => {
    return (
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/termoDeUso" exact component={TermoDeUso} />
            <Route
                path="/politicaDePrivacidade"
                exact
                component={PoliticaDePrivacidade}
            />
        </Switch>
    );
};

export default Routes;
