import React, { CSSProperties } from 'react';
import Button from '../Button';
import { Container, Icon, SubTitle, Title, UnderlinedTitle } from './styles';

interface ServiceCardProps {
    title: string;
    icon: string;
    description: string;

    containerStyle?: CSSProperties;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
    title,
    icon,
    description,
    containerStyle,
}) => {
    return (
        <Container style={{ ...containerStyle }}>
            <Icon src={icon} />
            <Title>{title}</Title>
            <UnderlinedTitle />
            <SubTitle>{description}</SubTitle>
            <Button style={{ marginTop: '25px' }}>SAIBA MAIS</Button>
        </Container>
    );
};

export default ServiceCard;
