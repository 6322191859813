import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1042px;
    width: 100%;
`;

export const Title = styled.p`
    align-self: center;
    font-size: 48px;
    font-weight: bold;
    color: ${colors.color_3F577B};
`;

export const UnderlinedTitle = styled.div`
    align-self: center;
    width: 150px;
    height: 3px;
    background-color: ${colors.color_4BB493};
`;

export const SubTitleContent = styled.div`
    align-self: center;
    max-width: 708px;
    display: flex;
    text-align: center;
`;

export const InfoText = styled.p`
    margin: 24px 0 38px 0;
    font-size: 16px;
    color: ${colors.color_838FAE};
`;

export const InputTitle = styled.p`
    font-size: 16px;
    color: ${colors.color_3F577B};
`;

export const InputContent = styled.div`
    display: flex;
    gap: 30px;
`;

export const ForgotTheCode = styled.p`
    margin-top: 14px;
    font-size: 14px;
    color: ${colors.color_3F577B};
    text-decoration: underline;
`;

export const TitleInfo = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: ${colors.color_3F577B};
`;

export const Description = styled.p`
    font-size: 16px;
    color: ${colors.color_3F577B};
`;

export const ForgotPassword = styled.p`
    margin-top: 6px;
    font-size: 14px;
    color: ${colors.color_3F577B};
    text-decoration: underline;
`;
