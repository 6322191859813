import React, { useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
    ButtonsContent,
    Container,
    Content,
    HeaderContent,
    ImgBackgroundHeader,
    ImgLogoMandeSeguro,
    SubTitle,
    TabItem,
    Tabs,
    Title,
    TitleContent,
} from './styles';
import BackgroundHeader from '../../assets/svgs/BackgroundHeader.svg';
import LogoMandeSeguro from '../../assets/svgs/LogoMandeSeguro.svg';
import Input from '../Input';
import Button from './Button';

const Header: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    return (
        <Container>
            <ImgBackgroundHeader src={BackgroundHeader} />
            <Content>
                <HeaderContent>
                    <ImgLogoMandeSeguro src={LogoMandeSeguro} />
                    <Tabs>
                        <TabItem>REGISTRO DE OBJETOS</TabItem>
                        <TabItem>NOSSOS SERVIÇOS</TabItem>
                        <TabItem>PLANOS</TabItem>
                        <TabItem>FALE CONOSCO</TabItem>
                        <Button>LOGIN</Button>
                    </Tabs>
                </HeaderContent>
                <TitleContent>
                    <Title>Mande Seguro Sua melhor escolha</Title>
                    <SubTitle>
                        Gerenciamento automatizado de toda sua operação de malotes,
                        mensageiras internas e delivery. Conte conosco para
                        facilitar, automatizar e informatizar os seus departamentos,
                        de forma simples e rápida.
                    </SubTitle>
                    <Form
                        ref={formRef}
                        onSubmit={() => {
                            // eslint-disable-next-line no-console
                            console.log('submit');
                        }}
                    >
                        <Input
                            name="code"
                            placeholder="DIGITE AQUI O CÓDIGO DE RASTREAMENTO"
                        />
                    </Form>
                    <ButtonsContent>
                        <Button
                            style={{ flex: 1, fontSize: '11px', height: '36px' }}
                        >
                            RASTREAMENTO DE OBJETOS
                        </Button>
                        <Button
                            style={{ flex: 1, fontSize: '11px', height: '36px' }}
                        >
                            REGISTRO DE OBJETOS
                        </Button>
                        <Button
                            style={{ flex: 1, fontSize: '11px', height: '36px' }}
                        >
                            CONFIRMAR RECEBIMENTO
                        </Button>
                    </ButtonsContent>
                </TitleContent>
            </Content>
        </Container>
    );
};

export default Header;
