import React, { useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
    Box,
    Container,
    FooterColumn,
    FooterRow,
    ImgBackgroundAppStore,
    ImgLogoMandeSeguro,
    TextFooter,
    TextNewNews,
    TitleTextFooter,
} from './styles';
import BackgroundAppStore from '../../assets/svgs/BackgroundAppStore.png';
import BackgroundFooter from '../../assets/svgs/BackgroundFooter.svg';
import LogoMandeSeguro from '../../assets/svgs/LogoMandeSeguro.svg';
import Input from './Input';

const Footer: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    return (
        <Container>
            <ImgBackgroundAppStore src={BackgroundAppStore} />
            <Box>
                <TextNewNews>Se inscreva para receber novidades</TextNewNews>
                <Form
                    ref={formRef}
                    onSubmit={() => {
                        // eslint-disable-next-line no-console
                        console.log('submit');
                    }}
                >
                    <Input name="email" placeholder="Digite o seu e-mail aqui" />
                </Form>
            </Box>
            <div
                style={{
                    marginTop: '-95px',
                    backgroundImage: `url('${BackgroundFooter}')`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                }}
            >
                <FooterRow>
                    <FooterColumn>
                        <ImgLogoMandeSeguro src={LogoMandeSeguro} />
                        <TextFooter>
                            Mande Seguro controle, rastreabilidadeInteligência e
                            acima de tudo compromisso.
                        </TextFooter>
                    </FooterColumn>
                    <FooterColumn>
                        <TitleTextFooter>Support</TitleTextFooter>
                        <TextFooter>FAQ</TextFooter>
                        <TextFooter>Fale Conosco</TextFooter>
                        <TextFooter>Termos de Uso</TextFooter>
                        <TextFooter>Política de Privacidade</TextFooter>
                    </FooterColumn>
                    <FooterColumn>
                        <TitleTextFooter>Download</TitleTextFooter>
                        <TextFooter>Android (Google Play)</TextFooter>
                        <TextFooter>Apple (App Store)</TextFooter>
                    </FooterColumn>
                    <FooterColumn>
                        <TitleTextFooter>Contact us</TitleTextFooter>
                        <TextFooter>Endereço</TextFooter>
                        <TextFooter>contato@mandeseguro.com.br</TextFooter>
                        <TextFooter>31 99999-9999</TextFooter>
                    </FooterColumn>
                </FooterRow>
                <TextFooter style={{ marginTop: '50px', textAlign: 'center' }}>
                    © 2021 copyright todos os direitos reservados a Mande Seguro
                </TextFooter>
            </div>
        </Container>
    );
};

export default Footer;
