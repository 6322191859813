import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import {
    Container,
    Content,
    InfoContent,
    InfoText,
    InfoTextBold,
    Title,
    UnderlinedTitle,
} from './styles';

const TermoDeUso: React.FC = () => {
    return (
        <Container>
            <Header />
            <Content>
                <Title>TERMO DE USO</Title>
                <UnderlinedTitle />
                <InfoContent>
                    <InfoText>
                        Estes termos e condições (daqui em diante referidos apenas
                        como “Termos”) se aplicam à utilização de Nosso Site, “MANDE
                        SEGURO”, por você. Nosso Site é mantido pela pessoa jurídica
                        Mande Seguro Tecnologia da Informação Ltda (“Mande Seguro”),
                        devidamente registrada sob o CNPJ n. 00208661000107, e-mail:
                        contato@mandeseguro.com.br, com sede em, Rua Zoroastro
                        Torres, 226, Bairro Santo Antônio, Belo Horizonte - MG -
                        30350-260
                    </InfoText>
                    <InfoTextBold>
                        Estes Termos se aplicam a todos aqueles que se cadastrarem em
                        Nosso Site, de modo que quem desejar concluir seu cadastro
                        deve ler cuidadosamente e concordar com as regras previstas
                        neste documento.{' '}
                    </InfoTextBold>
                    <InfoText>
                        Aqueles que não compreenderem ou que não aceitarem as normas
                        previstas nestes Termos não poderão se cadastrar em Nosso
                        Site e, consequentemente, não poderão acessar nem utilizar os
                        recursos restritos a usuários cadastrados.
                    </InfoText>

                    <InfoTextBold>1. DEFINIÇÕES</InfoTextBold>
                    <InfoText>
                        “Nós”, “Nosso” ou “Nossos” refere-se a Mande Seguro
                        Tecnologia da Informação Ltda (“Mande Seguro”), pessoa
                        jurídica que mantém e é responsável pelo Site.
                    </InfoText>
                    <InfoText>
                        “Site” é o site que pode ser acessado a partir do endereço
                        “www.mandeseguro.com.br”, incluindo todas as suas páginas e
                        recursos.
                    </InfoText>
                    <InfoText>
                        “Código de Defesa do Consumidor” ou “CDC” é Lei Federal n.
                        8.078, de 11 de setembro de 1990.
                    </InfoText>
                    <InfoText>
                        “Partes” se refere tanto ao Usuário quanto a Nós,
                        indistintamente.
                    </InfoText>
                    <InfoText>
                        “Serviços” se refere aos seguintes serviços prestados
                        diretamente por Nós por meio de Nosso Site:
                    </InfoText>
                    <InfoText>
                        Rastreamento de objetos enviados via malote, mensageria
                        interna, correios e entrega via transportadores privados.
                    </InfoText>
                    <InfoText>
                        “Nosso Conteúdo” se refere a toda e qualquer informação ou
                        dado publicado ou disponibilizado diretamente por Nós no
                        Site, incluindo, dentre outros possíveis, textos, imagens,
                        vídeos e áudios.
                    </InfoText>
                    <InfoText>
                        “Usuário” é a pessoa que navega ou que de qualquer outra
                        forma utiliza Nosso Site.
                    </InfoText>

                    <InfoTextBold>2. RESTRIÇÕES DE IDADE PARA CADASTRO</InfoTextBold>
                    <InfoText>
                        Somente poderão se cadastrar em Nosso Site pessoas maiores de
                        14 anos. Os menores de 18 anos não emancipados somente
                        poderão utilizar Nosso Site se estiverem devidamente
                        assistidas ou representadas por seus pais ou representantes
                        legais.
                    </InfoText>

                    <InfoTextBold>
                        3. MANUTENÇÃO, ALTERAÇÃO E EXCLUSÃO DO CADASTRO
                    </InfoTextBold>
                    <InfoText>
                        Cada Usuário poderá manter apenas uma conta junto ao Site.
                        Contas duplicadas, quando identificadas, serão
                        automaticamente desativadas por Nós, sem prejuízo de demais
                        penalidades cabíveis.
                    </InfoText>
                    <InfoText>
                        Para que o cadastro seja concluído, o Usuário deverá fornecer
                        integralmente os dados requeridos. Todas as informações
                        fornecidas pelo Usuário devem ser precisas, verdadeiras e
                        atualizadas. Em qualquer caso, o Usuário poderá responder, em
                        âmbito cível e criminal, pela veracidade, exatidão e
                        autenticidade dos dados informados.
                    </InfoText>
                    <InfoText>
                        O Usuário deverá fornecer um endereço de e-mail válido,
                        através do qual todos os contatos serão realizados. Todas as
                        comunicações enviadas para o referido endereço serão
                        consideradas lidas pelo usuário, que se compromete, assim, a
                        consultar regularmente as mensagens recebidas e a
                        respondê-las, se necessário, em prazo razoável.
                    </InfoText>
                    <InfoText>
                        Após a confirmação de seu cadastro, o usuário possuirá um
                        login e uma senha pessoais, que deverão ser por ele
                        utilizados para o acesso à sua conta no Site. Estes dados não
                        deverão ser informados pelo Usuário a terceiros, sendo de sua
                        inteira responsabilidade o uso que deles seja feito. O
                        usuário deverá Nos comunicar imediatamente caso suspeite da
                        ocorrência de quaisquer atividades suspeitas ou inesperadas
                        em sua conta.
                    </InfoText>
                    <InfoText>
                        Não será permitido ceder, vender, alugar ou transferir, de
                        qualquer forma, uma conta de Usuário, que é pessoal e
                        intransferível. Sempre que os dados de seu cadastro estiverem
                        desatualizados, o Usuário deverá corrigi-los por meio das
                        ferramentas que disponibilizamos para que isto seja feito.
                    </InfoText>
                    <InfoText>
                        O Usuário que descumprir quaisquer das normas contidas no
                        presente instrumento terá sua conta suspensa e será
                        notificado para se justificar. Caso não haja a apresentação
                        de justificativa dentro do prazo assinalado ou caso a
                        justificativa não seja aceita, seu cadastro será excluído,
                        sendo-lhe vedado realizar nova inscrição no Site.
                    </InfoText>
                    <InfoText>
                        O usuário poderá, a qualquer tempo e sem necessidade de
                        justificação, requerer o cancelamento de seu cadastro junto
                        ao Site. O seu descadastramento será realizado o mais
                        rapidamente possível, desde que não sejam verificados débitos
                        em aberto. Seja em caso de pedido de cancelamento de
                        cadastro, seja em caso de deleção por descumprimento das
                        normas aqui previstas, os dados pessoais do Usuário serão
                        tratados em conformidade com nossa Política de Privacidade,
                        sendo possível que todos ou alguns dados sejam mantidos mesmo
                        após a exclusão da conta, independentemente do consentimento
                        do titular dos dados pessoais.
                    </InfoText>

                    <InfoTextBold>4. DA NAVEGAÇÃO</InfoTextBold>
                    <InfoText>
                        Nos comprometemos a utilizar todas as soluções técnicas à
                        nossa disposição para que o Site possa ser acessado 24 (vinte
                        e quatro) horas por dia, todos os dias. Entretanto, Nós
                        poderemos, a qualquer momento, interromper, limitar ou
                        suspender o acesso ao Site ou a algumas de suas páginas ou
                        recursos, a fim de realizar atualizações, modificações,
                        correções ou qualquer outra ação que consideremos necessária
                        para garantir seu bom funcionamento.
                    </InfoText>

                    <InfoTextBold>5. DA GESTÃO DO SITE</InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        Para a boa gestão, Nós poderemos, a qualquer momento:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        a) suspender, interromper ou limitar o acesso a todo ou a
                        parte do Site a uma categoria específica ou a todos os
                        Usuários;
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        b) remover toda informação que possa perturbar o
                        funcionamento do Site ou que esteja em conflito com normas de
                        Direito brasileiro ou de Direito internacional;
                    </InfoText>
                    <InfoText>
                        c) suspender o Site, a fim de realizar atualizações e
                        modificações.
                    </InfoText>

                    <InfoTextBold>6. NOSSAS RESPONSABILIDADES</InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        Embora adotemos tudo o que está ao Nosso alcance para manter
                        Nosso Site em pleno e em correto funcionamento, Nós nos
                        responsabilizaremos pelos defeitos ou vícios eventualmente
                        encontrados no programa de computador que compõe Nosso Site e
                        que tenham sido por Nós causados, desde que haja comprovado
                        dano.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Defeitos ou problemas surgidos no âmbito ou em decorrência
                        dos sistemas ou equipamentos utilizados pelo Usuário para
                        acessar e utilizar Nosso Site não serão de Nossa
                        responsabilidade.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Nós não nos responsabilizamos pelos usos que os Usuários
                        fizerem de Nosso Site, cabendo-lhes responder pessoal e
                        exclusivamente por suas próprias ações.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Nós não Nos responsabilizaremos por eventuais danos
                        decorrentes de caso fortuito, força maior ou por aqueles que
                        forem decorrentes das ações de terceiros.{' '}
                    </InfoText>
                    <InfoText>
                        Nós somos responsáveis pelos Nossos Serviços comercializados
                        no âmbito do Site e nos comprometemos a prestá-los de acordo
                        com as condições estabelecidas durante a contratação. Não Nos
                        responsabilizamos, porém, em situações que fujam ao nosso
                        controle, como nas situações em que o Usuário contratante nos
                        repassar informações falsas ou incorretas ou em que houver
                        caso fortuito ou força maior que prejudique ou que impeça que
                        cumpramos nossos compromissos a tempo e modo.
                    </InfoText>

                    <InfoTextBold>7. USO DO SITE</InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        O Usuário deverá utilizar as funcionalidades do Site para
                        finalidades que não contrariem a lei, a moral ou os bons
                        costumes. Em específico, deverá se limitar a utilizá-las em
                        conformidade com os objetivos para as quais foram criadas.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        O Usuário não poderá utilizar crawlers, robôs ou quaisquer
                        programas de computador, algoritmos, equipamentos ou
                        metodologias automatizados para acessar, copiar, ler,
                        monitorar, navegar, preencher formulários ou publicar
                        conteúdo no Site.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        O Usuário não poderá utilizar quaisquer meios ou ferramentas
                        automatizados ou manuais para acessar qualquer conteúdo ou
                        informação que não lhe tenha sido intencionalmente
                        disponibilizada por Nós, incluindo as informações constantes
                        de seções de Nosso Site e as que estejam armazenadas ou que
                        trafeguem no âmbito da infraestrutura de Tecnologia da
                        Informação utilizada por Nós, que inclui equipamentos, redes
                        e servidores.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        O Usuário não poderá violar nem testar as vulnerabilidades
                        dos mecanismos de segurança do Site ou da infraestrutura de
                        Tecnologia da Informação utilizada por Nós.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        O Usuário não poderá utilizar quaisquer dos conteúdos ou das
                        informações disponibilizadas em Nosso Site para fins de
                        identificar outros Usuários ou outras pessoas, a menos que o
                        conteúdo ou informação seja licitamente divulgado com este
                        objetivo.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        O Usuário não poderá sobrecarregar a infraestrutura de
                        Tecnologia da Informação que mantém o Site com um número
                        excessivo ou desarrazoado de requisições.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        O Usuário não poderá esconder sua identidade nem a origem de
                        sua conexão, nem poderá se passar por outra pessoa. Neste
                        sentido, além de outras condutas possíveis, o Usuário não
                        poderá utilizar quaisquer ferramentas ou mecanismos para
                        manipular ou para mascarar a origem de qualquer mensagem
                        enviada a Nós ou à Nossa infraestrutura de Tecnologia da
                        Informação por meio de Nosso Site ou de qualquer de suas
                        funcionalidades.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        O Usuário cadastrado será responsável pelo uso que for feito
                        de suas contas de usuário, devendo zelar para que suas
                        credenciais de acesso (login e senha) não sejam
                        compartilhados com terceiros.
                    </InfoText>
                    <InfoText>
                        O Usuário tem a responsabilidade de garantir a segurança dos
                        equipamentos eletrônicos e sistemas que utilizar para acessar
                        o Site.
                    </InfoText>

                    <InfoTextBold>8. LINKS EXTERNOS</InfoTextBold>
                    <InfoText>
                        Nosso Site pode conter links externos que redirecionam o
                        Usuário para outras páginas da internet sobre as quais Nós
                        não exercemos controle. Apesar das verificações prévias e
                        regulares que realizamos, Nos isentamos de qualquer
                        responsabilidade sobre o conteúdo encontrado nas páginas e
                        aplicações que possam ser acessadas a partir destes links.
                    </InfoText>

                    <InfoTextBold>
                        9. DOS DIREITOS DE PROPRIEDADE INTELECTUAL SOBRE PROGRAMAS DE
                        COMPUTADOR E SOBRE NOSSO CONTEÚDO{' '}
                    </InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        Os programas de computador que compõem o Site e o Nosso
                        Conteúdo disponibilizado no Site são de Nossa propriedade, a
                        menos que façamos menção expressa em sentido diverso.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Nossos programas de computador e o Nosso Conteúdo que forem
                        protegidos por direitos autorais não poderão ser, integral ou
                        parcialmente, copiados, reproduzidos, representados,
                        adaptados ou de qualquer forma alterados, por qualquer meio e
                        para qualquer finalidade, sem Nossa autorização prévia,
                        expressa e por escrito. Em caso de violações a Nossos
                        direitos de propriedade intelectual, Nós nos resguardamos o
                        direito de adotar todas as providências judiciais e
                        extrajudiciais cabíveis.
                    </InfoText>
                    <InfoText>
                        O acesso ao Site não gera para o Usuário qualquer direito de
                        propriedade intelectual relativo a elementos do Site.
                    </InfoText>

                    <InfoTextBold>10. DOS SERVIÇOS</InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        Por intermédio do Site, Nós fornecemos ao cliente um catálogo
                        ou uma loja online apresentando com exatidão os Serviços
                        vendidos. Os Serviços estão descritos e apresentados com o
                        maior grau de precisão possível, acompanhandos de informações
                        corretas, claras, precisas, ostensivas e em língua portuguesa
                        sobre suas características, entre outros dados, bem como
                        sobre os eventuais riscos que apresentam à saúde e segurança
                        do Usuário que os adquirir.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Os preços e as taxas referentes à venda dos Serviços estão
                        precisadas no catálogo ou na loja online.
                    </InfoText>
                    <InfoText>
                        Antes de finalizar a compra de determinado Serviço, o Usuário
                        deverá se informar sobre as suas especificações e sobre a sua
                        destinação. Em se tratando de Serviço adquirido no âmbito de
                        ofertas, o Usuário deverá também observar suas condições de
                        aplicação. As ofertas serão disponibilizadas no Site de
                        acordo com a Nossa conveniência.
                    </InfoText>

                    <InfoTextBold>11. DOS PREÇOS DOS SERVIÇOS</InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        O vendedor se reserva o direito de modificar os preços dos
                        Serviços a qualquer momento, publicando-os no Site.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Serão aplicadas as tarifas em vigor no momento do pedido, sob
                        reserva de disponibilidade de Serviços nesta data.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Os preços serão indicados em reais e não incluirão as taxas
                        de entrega, as quais virão especificadas à parte e serão
                        informadas antes da realização do pedido.
                    </InfoText>
                    <InfoText>
                        O montante total do pedido, incluindo todas as taxas, será
                        indicado antes da validação final do pedido.
                    </InfoText>

                    <InfoTextBold>12. DO PAGAMENTO</InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        O pagamento do pedido deverá ser realizado à vista ou à
                        prazo, através dos seguintes meios:
                    </InfoText>
                    <InfoText>
                        Boleto Bancário, Cartão de Crédito, Cartão de Débito e PIX.
                    </InfoText>

                    <InfoTextBold>13. DA PRESTAÇÃO DOS SERVIÇOS</InfoTextBold>
                    <InfoText>
                        Os Serviços serão prestados por Nós em conformidade com as
                        especificações, condições e demais informações disponíveis em
                        Nosso Site.
                    </InfoText>

                    <InfoTextBold>14. DO DIREITO DE ARREPENDIMENTO</InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        Dentro do prazo de 7 (sete) dias, contados da data da
                        contratação do serviço, o cliente poderá desistir da compra e
                        solicitar a devolução dos valores pagos, sem necessidade de
                        apresentação de quaisquer justificativas.
                    </InfoText>
                    <InfoText>
                        Neste caso, ele deverá fazer retornar ao vendedor o produto
                        ou serviço adquirido, nas mesmas condições em que o recebeu.
                    </InfoText>

                    <InfoTextBold>15. DAS TROCAS E DEVOLUÇÕES</InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        A política de trocas e de devoluções do Site será regida
                        conforme o Código de Defesa do Consumidor (Lei Federal n.
                        8.078, de 11 de setembro de 1990).
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Afora a hipótese de arrependimento do cliente, a troca ou a
                        devolução dos produtos ou dos serviços adquiridos apenas será
                        realizada mediante verificação de vícios de qualidade ou
                        quantidade que os tornem impróprios ou inadequados ao consumo
                        a que se destinam ou que lhes diminuam o valor. Também
                        poderão ser trocados ou devolvidos os produtos ou serviços
                        que apresentem disparidade com as indicações constantes do
                        recipiente, da embalagem, rotulagem ou mensagem publicitária,
                        respeitadas as variações decorrentes de sua natureza.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        O Usuário deverá entrar em contato conosco, por meio de Nosso
                        serviço de atendimento, tão logo constate o vício no produto
                        ou serviço adquirido.
                    </InfoText>
                    <InfoText>
                        Se, no prazo máximo de 30 (trinta) dias, não for possível
                        resolver o vício ou, independente deste prazo, a substituição
                        das partes viciadas puder comprometer a qualidade ou
                        características do produto ou serviço, diminuir-lhe o valor
                        ou se tratar de produto ou serviço essencial, o Usuário
                        poderá optar pela substituição do produto por outro de mesma
                        espécie ou pela reexecução do serviço, pela devolução da
                        quantia paga ou pelo abatimento proporcional do preço.
                    </InfoText>

                    <InfoTextBold>16. DA PROTEÇÃO DE DADOS PESSOAIS</InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        Nós tratamos os dados pessoais dos Usuários em conformidade
                        com a Lei Federal n. 13.709/2018 (Lei Geral de Proteção de
                        Dados Pessoais) e demais normas correlatas que Nos são
                        aplicáveis. Mais informações sobre este assunto poderão ser
                        consultadas em nossa Política de Privacidade, que pode ser
                        acessada a partir do link:
                        www.mandeseguro.com.br/privacidade.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Na referida Política, identificamos os dados pessoais que
                        tratamos e prestamos informações sobre como, por qual motivo
                        e com qual fundamento legal o fazemos, bem como sobre a forma
                        pela qual os Usuários podem exercer seus direitos
                        relacionados à proteção de seus dados pessoais e sobre como
                        podem entrar em contato com nosso Encarregado de Proteção de
                        Dados Pessoais.
                    </InfoText>
                    <InfoText>
                        Os pedidos de alteração, retificação ou exclusão de dados
                        pessoais Nos devem ser direcionados em conformidade com o
                        previsto naquela Política.
                    </InfoText>

                    <InfoTextBold>
                        17. DO SERVIÇO DE ATENDIMENTO AO USUÁRIO
                    </InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        Em caso de dúvidas, sugestões ou problemas com a utilização
                        do Site, o Usuário poderá contatar diretamente Nosso serviço
                        de atendimento, através do endereço de e-mail:
                        contato@mandeseguro.com.br ou do telefone: (31) 99984-7210.
                        Estes serviços de atendimento ao Usuário estarão disponíveis
                        nos seguintes dias e horários:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Segunda à Sexta-feira, das 09h00 às 13h00.
                    </InfoText>
                    <InfoText>
                        O Usuário poderá, ainda, optar por enviar correspondência ao
                        endereço da sede do Site, informado no início deste
                        documento.
                    </InfoText>

                    <InfoTextBold>18. DAS SANÇÕES</InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        Sem prejuízo das demais medidas legais cabíveis, Nós
                        poderemos, a qualquer momento, advertir, suspender ou
                        cancelar a conta do Usuário:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        a) que descumprir quaisquer dos dispositivos contidos no
                        presente instrumento;
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        b) que descumprir os seus deveres de Usuário;
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        c) que praticar atos fraudulentos, ilegais ou imorais;
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        d) que fornecer qualquer informação incorreta;
                    </InfoText>
                    <InfoText>
                        e) cujo comportamento constitua ou possa vir a importar
                        ofensa ou dano a terceiro ou ao próprio Site.
                    </InfoText>

                    <InfoTextBold>19. DAS ALTERAÇÕES</InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        A presente versão dos termos e condições gerais de uso foi
                        atualizada pela última vez em: 14/05/2021.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Nós nos reservamos o direito de modificar, a qualquer momento
                        e sem qualquer aviso prévio, o site e os serviços, bem como
                        as presentes normas, especialmente para adaptá-las às
                        evoluções do Site, seja pela disponibilização de novas
                        funcionalidades, seja pela supressão ou modificação daquelas
                        já existentes.
                    </InfoText>
                    <InfoText>
                        Dessa forma, convidamos o Usuário a consultar periodicamente
                        esta página para verificar as atualizações.
                    </InfoText>

                    <InfoTextBold>20. DO DIREITO APLICÁVEL E DO FORO</InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        Para a solução das controvérsias decorrentes do presente
                        instrumento, será aplicado integralmente o Direito
                        brasileiro.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Os eventuais litígios deverão ser apresentados no foro da
                        comarca em que se encontra a sede do editor do site.
                    </InfoText>

                    <InfoTextBold style={{ marginTop: '50px', marginBottom: '0' }}>
                        Seja bem-vindo(a)!
                    </InfoTextBold>
                    <InfoText style={{ marginBottom: '0' }}>
                        A equipe do site MANDE SEGURO lhe deseja uma excelente
                        navegação!
                    </InfoText>
                </InfoContent>
                <Footer />
            </Content>
        </Container>
    );
};

export default TermoDeUso;
