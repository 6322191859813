export default {
    color_FFFFFF: '#FFFFFF',
    color_000000: '#000000',
    color_3F577B: '#3F577B',
    color_4BB493: '#4BB493',
    color_1F65FF1A: '#1F65FF1A',
    color_F2F5F8: '#F2F5F8',
    color_8CA0BB: '#8CA0BB',
    color_838FAE: '#838FAE',
    color_5d70df1a: '#5d70df1a',
    color_243668: '#243668',
    color_F9FBFF: '#F9FBFF',
    color_5D70DF3D: '#5D70DF3D',
    color_44537E: '#44537E',
    color_8F90AE: '#8F90AE',
    color_B7B7BC: '#B7B7BC',
    color_F5F4F4: '#F5F4F4',
};
