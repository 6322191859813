import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Container = styled.button`
    border: none;
    width: 100px;
    height: 30px;
    font-size: 14px;
    font-weight: bold;
    color: ${colors.color_3F577B};
    background-color: ${colors.color_FFFFFF};
    border-radius: 4px;
`;
