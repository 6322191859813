import React, { CSSProperties } from 'react';
import { Container, Content, SubTitle, Title, Value } from './styles';
import BackgroundCardHeader from '../../assets/svgs/BackgroundCardHeader.svg';
import BackgroundCardHeaderLight from '../../assets/svgs/BackgroundCardHeaderLight.svg';

interface ValueCardProps {
    title: string;
    value: string;
    type?: 'light';

    containerStyle?: CSSProperties;
}

const ValueCard: React.FC<ValueCardProps> = ({
    title,
    value,
    type,
    children,
    containerStyle,
}) => {
    return (
        <Container style={{ ...containerStyle }}>
            <div
                style={{
                    backgroundImage: type
                        ? `url('${BackgroundCardHeaderLight}')`
                        : `url('${BackgroundCardHeader}')`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '280px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                }}
            >
                <Title type={type}>{title}</Title>
                <Value type={type}>{value}</Value>
                <SubTitle type={type}>Per month</SubTitle>
            </div>
            <Content>{children}</Content>
        </Container>
    );
};

export default ValueCard;
