import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ImgBackgroundAppStore = styled.img`
    width: 100%;
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    width: 100%;
    height: 190px;
    max-width: 830px;
    row-gap: 30px;
    background-color: ${colors.color_FFFFFF};
    box-shadow: 0px -3px 16px ${colors.color_1F65FF1A};
    border-radius: 10px;
    z-index: 1;
`;

export const TextNewNews = styled.p`
    font-size: 30px;
    font-weight: bold;
    color: ${colors.color_3F577B};
`;

export const InfoContent = styled.div`
    position: relative;
    width: 100%;
    z-index: 1;
`;

export const FooterRow = styled.div`
    margin-top: 180px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 85px;
    width: calc(100% - 2 * 85px);
`;

export const FooterColumn = styled.div``;

export const TitleTextFooter = styled.p`
    font-size: 16px;
    margin-bottom: 15px;
    color: ${colors.color_FFFFFF};
`;

export const TextFooter = styled.p`
    font-size: 14px;
    margin-bottom: 15px;
    color: ${colors.color_8CA0BB};
`;

export const ImgLogoMandeSeguro = styled.img`
    width: 150px;
    margin-bottom: 15px;
`;
