import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
    min-width: 1000px;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;

export const Title = styled.p`
    font-size: 48px;
    color: ${colors.color_3F577B};
    font-weight: bold;
`;

export const UnderlinedTitle = styled.div`
    width: 150px;
    height: 3px;
    background-color: ${colors.color_4BB493};
`;

export const InfoContent = styled.div`
    margin: 45px 0;
    display: flex;
    width: calc(100% - 140px);
    flex-direction: column;
`;

export const InfoText = styled.p`
    font-size: 16px;
    color: ${colors.color_000000};
    margin-bottom: 30px;
`;

export const InfoTextBold = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: ${colors.color_3F577B};
    margin-bottom: 30px;
`;
