import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Container = styled.div`
    display: flex;
    width: 600px;
    height: 36px;
    border: 2px solid ${colors.color_FFFFFF};
    background-color: ${colors.color_F2F5F8};
    border-radius: 4px;
`;

export const InputContainer = styled.input`
    padding: 0 20px;
    width: 100%;
    height: 100%;
    font-size: 13px;
    background-color: transparent;
    border: none;
    color: ${colors.color_8CA0BB};

    ::placeholder {
        color: ${colors.color_8CA0BB};
    }
`;

export const Button = styled.button`
    border: none;
    background-color: ${colors.color_3F577B};
    width: 120px;
    font-size: 12px;
    color: ${colors.color_FFFFFF};
    border-radius: 4px;
`;
