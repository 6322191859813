import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import {
    Container,
    Content,
    InfoContent,
    InfoText,
    Title,
    UnderlinedTitle,
} from './styles';

const PoliticaDePrivacidade: React.FC = () => {
    return (
        <Container>
            <Header />
            <Content>
                <Title>POLÍTICA DE PRIVACIDADE</Title>
                <UnderlinedTitle />
                <InfoContent>
                    <InfoText style={{ marginBottom: '0' }}>
                        Este site é mantido e operado por Mande Seguro Tecnologia da
                        Informação Ltda.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Nós coletamos e utilizamos alguns dados pessoais que
                        pertencem àqueles que utilizam nosso site. Ao fazê-lo, agimos
                        na qualidade de controlador desses dados e estamos sujeitos
                        às disposições da Lei Federal n. 13.709/2018 (Lei Geral de
                        Proteção de Dados Pessoais - LGPD).
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Nós cuidamos da proteção de seus dados pessoais e, por isso,
                        disponibilizamos esta política de privacidade, que contém
                        informações importantes sobre:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        - Quem deve utilizar nosso site
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        - Quais dados coletamos e o que fazemos com eles;
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        - Seus direitos em relação aos seus dados pessoais; e
                    </InfoText>
                    <InfoText>- Como entrar em contato conosco.</InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        1. Quem deve utilizar nosso site
                    </InfoText>
                    <InfoText>
                        Nosso site somente deve ser utilizado por pessoas que tenham,
                        pelo menos, 14 (quatorze) anos de idade, sendo que a
                        utilização por pessoa com menos de 18 (dezoito) anos somente
                        será possível mediante o consentimento de pelo menos um de
                        seus pais ou responsável.
                    </InfoText>
                    <InfoText>2. Dados que coletamos e motivos da coleta</InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Nosso site coleta e utiliza alguns dados pessoais de nossos
                        usuários, de acordo com o disposto nesta seção.
                    </InfoText>
                    <InfoText>
                        1. Dados pessoais fornecidos expressamente pelo usuário
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Nós coletamos os seguintes dados pessoais que nossos usuários
                        nos fornecem expressamente ao utilizar nosso site:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Nome Completo, CPF, E-mail e Telefone
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        A coleta destes dados ocorre nos seguintes momentos:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Quando o usuário faz seu cadastro no site.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Os dados fornecidos por nossos usuários são coletados com as
                        seguintes finalidades:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Para que o site possa identificar o usuário.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        2. Dados sensíveis
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Não serão coletados dados sensíveis de nossos usuários, assim
                        entendidos aqueles definidos nos arts. 11 e seguintes da Lei
                        de Proteção de Dados Pessoais. Assim, não haverá coleta de
                        dados sobre origem racial ou étnica, convicção religiosa,
                        opinião política, filiação a sindicato ou a organização de
                        caráter religioso, filosófico ou político, dado referente à
                        saúde ou à vida sexual, dado genético ou biométrico, quando
                        vinculado a uma pessoa natural.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>3. Cookies</InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Cookies são pequenos arquivos de texto baixados
                        automaticamente em seu dispositivo quando você acessa e
                        navega por um site. Eles servem, basicamente, para seja
                        possível identificar dispositivos, atividades e preferências
                        de usuários.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Os cookies não permitem que qualquer arquivo ou informação
                        sejam extraídos do disco rígido do usuário, não sendo
                        possível, ainda, que, por meio deles, se tenha acesso a
                        informações pessoais que não tenham partido do usuário ou da
                        forma como utiliza os recursos do site.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        a. Cookies do site
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Os cookies do site são aqueles enviados ao computador ou
                        dispositivo do usuário e administrador exclusivamente pelo
                        site.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        As informações coletadas por meio destes cookies são
                        utilizadas para melhorar e personalizar a experiência do
                        usuário, sendo que alguns cookies podem, por exemplo, ser
                        utilizados para lembrar as preferências e escolhas do
                        usuário, bem como para o oferecimento de conteúdo
                        personalizado.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        b. Gestão de cookies
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        O usuário poderá se opor ao registro de cookies pelo site,
                        bastando que desative esta opção no seu próprio navegador.
                        Mais informações sobre como fazer isso em alguns dos
                        principais navegadores utilizados hoje podem ser acessadas a
                        partir dos seguintes links:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Internet Explorer:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>Safari:</InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        https://support.apple.com/pt-br/guide/safari/sfri11471/mac
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>Google Chrome:</InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Mozila Firefox:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>Opera:</InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        https://www.opera.com/help/tutorials/security/privacy/
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        A desativação dos cookies, no entanto, pode afetar a
                        disponibilidade de algumas ferramentas e funcionalidades do
                        site, comprometendo seu correto e esperado funcionamento.
                        Outra consequência possível é remoção das preferências do
                        usuário que eventualmente tiverem sido salvas, prejudicando
                        sua experiência.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        4. Coleta de dados não previstos expressamente
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Eventualmente, outros tipos de dados não previstos
                        expressamente nesta Política de Privacidade poderão ser
                        coletados, desde que sejam fornecidos com o consentimento do
                        usuário, ou, ainda, que a coleta seja permitida com
                        fundamento em outra base legal prevista em lei.
                    </InfoText>
                    <InfoText>
                        Em qualquer caso, a coleta de dados e as atividades de
                        tratamento dela decorrentes serão informadas aos usuários do
                        site.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        3. Compartilhamento de dados pessoais com terceiros
                    </InfoText>
                    <InfoText>
                        Nós não compartilhamos seus dados pessoais com terceiros.
                        Apesar disso, é possível que o façamos para cumprir alguma
                        determinação legal ou regulatória, ou, ainda, para cumprir
                        alguma ordem expedida por autoridade pública.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        4. Por quanto tempo seus dados pessoais serão armazenados
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Os dados pessoais coletados pelo site são armazenados e
                        utilizados por período de tempo que corresponda ao necessário
                        para atingir as finalidades elencadas neste documento e que
                        considere os direitos de seus titulares, os direitos do
                        controlador do site e as disposições legais ou regulatórias
                        aplicáveis.
                    </InfoText>
                    <InfoText>
                        Uma vez expirados os períodos de armazenamento dos dados
                        pessoais, eles são removidos de nossas bases de dados ou
                        anonimizados, salvo nos casos em que houver a possibilidade
                        ou a necessidade de armazenamento em virtude de disposição
                        legal ou regulatória.
                    </InfoText>
                    <InfoText>
                        5. Bases legais para o tratamento de dados pessoais
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Cada operação de tratamento de dados pessoais precisa ter um
                        fundamento jurídico, ou seja, uma base legal, que nada mais é
                        que uma justificativa que a autorize, prevista na Lei Geral
                        de Proteção de Dados Pessoais.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Todas as Nossas atividades de tratamento de dados pessoais
                        possuem uma base legal que as fundamenta, dentre as
                        permitidas pela legislação.
                    </InfoText>
                    <InfoText>
                        Mais informações sobre as bases legais que utilizamos para
                        operações de tratamento de dados pessoais específicas podem
                        ser obtidas a partir de nossos canais de contato, informados
                        ao final desta Política.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        6. Direitos do usuário
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        O usuário do site possui os seguintes direitos, conferidos
                        pela Lei de Proteção de Dados Pessoais:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        - confirmação da existência de tratamento;
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        - acesso aos dados;
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        - correção de dados incompletos, inexatos ou desatualizados;
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        - anonimização, bloqueio ou eliminação de dados
                        desnecessários, excessivos ou tratados em desconformidade com
                        o disposto na lei;
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        - portabilidade dos dados a outro fornecedor de serviço ou
                        produto, mediante requisição expressa, de acordo com a
                        regulamentação da autoridade nacional, observados os segredos
                        comercial e industrial;
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        - eliminação dos dados pessoais tratados com o consentimento
                        do titular, exceto nos casos previstos em lei;
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        - informação das entidades públicas e privadas com as quais o
                        controlador realizou uso compartilhado de dados;
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        - informação sobre a possibilidade de não fornecer
                        consentimento e sobre as consequências da negativa;
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        - revogação do consentimento.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        É importante destacar que, nos termos da LGPD, não existe um
                        direito de eliminação de dados tratados com fundamento em
                        bases legais distintas do consentimento, a menos que os dados
                        seja desnecessários, excessivos ou tratados em
                        desconformidade com o previsto na lei.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        1. Como o titular pode exercer seus direitos
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Os titulares de dados pessoais tratados por nós poderão
                        exercer seus direitos por meio do formulário disponibilizado
                        no seguinte caminho:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        www.mandeseguro.com.br/privacidade. Alternativamente, se
                        desejar, o titular poderá enviar um e-mail ou uma
                        correspondência ao nosso Encarregado de Proteção de Dados
                        Pessoais. As informações necessárias para isso estão na seção
                        “Como entrar em contato conosco” desta Política de
                        Privacidade.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Os titulares de dados pessoais tratados por nós poderão
                        exercer seus direitos a partir do envio de mensagem ao nosso
                        Encarregado de Proteção de Dados Pessoais, seja por e-mail ou
                        por correspondência. As informações necessárias para isso
                        estão na seção “Como entrar em contato conosco” desta
                        Política de Privacidade.
                    </InfoText>
                    <InfoText>
                        Para garantir que o usuário que pretende exercer seus
                        direitos é, de fato, o titular dos dados pessoais objeto da
                        requisição, poderemos solicitar documentos ou outras
                        informações que possam auxiliar em sua correta identificação,
                        a fim de resguardar nossos direitos e os direitos de
                        terceiros. Isto somente será feito, porém, se for
                        absolutamente necessário, e o requerente receberá todas as
                        informações relacionadas.
                    </InfoText>
                    <InfoText>
                        7. Medidas de segurança no tratamento de dados pessoais
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Empregamos medidas técnicas e organizativas aptas a proteger
                        os dados pessoais de acessos não autorizados e de situações
                        de destruição, perda, extravio ou alteração desses dados.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        As medidas que utilizamos levam em consideração a natureza
                        dos dados, o contexto e a finalidade do tratamento, os riscos
                        que uma eventual violação geraria para os direitos e
                        liberdades do usuário, e os padrões atualmente empregados no
                        mercado por empresas semelhantes à nossa.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Entre as medidas de segurança adotadas por nós, destacamos as
                        seguintes:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Armazenamento de senhas utilizando hashes criptográficos,
                        restrições de acessos a banco de dados e monitoramento de
                        acesso físicos a servidores.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Ainda que adote tudo o que está ao seu alcance para evitar
                        incidentes de segurança, é possível que ocorra algum problema
                        motivado exclusivamente por um terceiro - como em caso de
                        ataques de hackers ou crackers ou, ainda, em caso de culpa
                        exclusiva do usuário, que ocorre, por exemplo, quando ele
                        mesmo transfere seus dados a terceiro. Assim, embora sejamos,
                        em geral, responsáveis pelos dados pessoais que tratamos, nos
                        eximimos de responsabilidade caso ocorra uma situação
                        excepcional como essas, sobre as quais não temos nenhum tipo
                        de controle.
                    </InfoText>
                    <InfoText>
                        De qualquer forma, caso ocorra qualquer tipo de incidente de
                        segurança que possa gerar risco ou dano relevante para
                        qualquer de nossos usuários, comunicaremos os afetados e a
                        Autoridade Nacional de Proteção de Dados acerca do ocorrido,
                        em conformidade com o disposto na Lei Geral de Proteção de
                        Dados.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        8. Reclamação a uma autoridade de controle
                    </InfoText>
                    <InfoText>
                        Sem prejuízo de qualquer outra via de recurso administrativo
                        ou judicial, os titulares de dados pessoais que se sentirem,
                        de qualquer forma, lesados, podem apresentar reclamação à
                        Autoridade Nacional de Proteção de Dados.
                    </InfoText>
                    <InfoText>9. Alterações nesta política </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        A presente versão desta Política de Privacidade foi
                        atualizada pela última vez em: 14/05/2021.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Reservamo-nos o direito de modificar, a qualquer momento, as
                        presentes normas, especialmente para adaptá-las às eventuais
                        alterações feitas em nosso site, seja pela disponibilização
                        de novas funcionalidades, seja pela supressão ou modificação
                        daquelas já existentes.
                    </InfoText>
                    <InfoText>
                        Sempre que houver uma modificação, nossos usuários serão
                        notificados acerca da mudança.
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        10. Como entrar em contato conosco
                    </InfoText>
                    <InfoText>
                        Para esclarecer quaisquer dúvidas sobre esta Política de
                        Privacidade ou sobre os dados pessoais que tratamos, entre em
                        contato com nosso Encarregado de Proteção de Dados Pessoais,
                        por algum dos canais mencionados abaixo:
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        E-mail: contato@mandeseguro.com.br
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Telefone: +55 31 99984-7210
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Endereço postal: Rua Engenheiro Zoroastro Torres, 226
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Bairro Santo Antônio
                    </InfoText>
                    <InfoText style={{ marginBottom: '0' }}>
                        Belo Horizonte - MG
                    </InfoText>
                    <InfoText style={{ marginBottom: '50px' }}>30350-260</InfoText>
                </InfoContent>
                <Footer />
            </Content>
        </Container>
    );
};

export default PoliticaDePrivacidade;
