import React, { InputHTMLAttributes, CSSProperties, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { FiCheck } from 'react-icons/fi';
import { Container, Content, InputContainer, SearchButton, Label } from './styles';
import colors from '../../../../styles/colors';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;

    label?: string;
    containerStyle?: CSSProperties;
}

const Input: React.FC<InputProps> = ({ name, containerStyle, label, ...rest }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldName, defaultValue, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <Container>
            {label && <Label>{label}</Label>}
            <Content style={{ ...containerStyle }}>
                <InputContainer
                    defaultValue={defaultValue}
                    ref={inputRef}
                    {...rest}
                />
                <SearchButton>
                    <FiCheck color={`${colors.color_3F577B}`} size={20} />
                </SearchButton>
            </Content>
        </Container>
    );
};

export default Input;
