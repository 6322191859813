import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

interface TypeCard {
    type?: 'light';
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 390px;
    height: 630px;
    background-color: ${colors.color_FFFFFF};
    box-shadow: 0px 5px 32px ${colors.color_5D70DF3D};
    border-radius: 10px;
`;

export const Title = styled.p<TypeCard>`
    font-size: 22px;
    font-weight: 500;
    color: ${colors.color_FFFFFF};
    text-align: center;

    ${({ type }) =>
        type &&
        css`
            color: ${colors.color_3F577B};
        `}
`;

export const Value = styled.p<TypeCard>`
    margin-top: 10px;
    font-size: 45px;
    font-weight: 500;
    color: ${colors.color_FFFFFF};
    text-align: center;

    ${({ type }) =>
        type &&
        css`
            color: ${colors.color_3F577B};
        `}
`;

export const SubTitle = styled.p<TypeCard>`
    margin-top: 3px;
    font-size: 18px;
    color: ${colors.color_FFFFFF};
    text-align: center;

    ${({ type }) =>
        type &&
        css`
            color: ${colors.color_3F577B};
        `}
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100% - 280px);
`;
