import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(348px - 2 * 20px);
    height: 380px;
    background-color: ${colors.color_FFFFFF};
    box-shadow: 0px 3px 8px ${colors.color_5d70df1a};
    border-radius: 10px;
`;

export const Icon = styled.img`
    height: 75px;
`;

export const Title = styled.p`
    margin-top: 25px;
    font-size: 23px;
    font-weight: bold;
    color: ${colors.color_243668};
    text-align: center;
`;

export const UnderlinedTitle = styled.div`
    margin-top: 15px;
    width: 100px;
    height: 4px;
    background-color: ${colors.color_4BB493};
`;

export const SubTitle = styled.p`
    margin-top: 15px;
    font-size: 16px;
    color: ${colors.color_838FAE};
    text-align: center;
`;
