import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
    position: relative;
    width: 100%;
`;

export const ImgBackgroundHeader = styled.img`
    width: 100%;
`;

export const Content = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    padding: 35px 85px;
    width: calc(100% - 2 * 85px);
    display: flex;
    flex-direction: column;
`;

export const HeaderContent = styled.div`
    width: 100%;
    display: flex;
`;

export const Tabs = styled.div`
    margin-left: auto;
    height: 60px;
    display: flex;
    align-items: center;
`;

export const ImgLogoMandeSeguro = styled.img`
    width: 150px;
`;

export const TabItem = styled.p`
    margin-right: 20px;
    font-size: 16px;
    color: ${colors.color_FFFFFF};
`;

export const TitleContent = styled.div`
    margin-top: 60px;
    width: 800px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @media (max-width: 1670px) {
        width: 600px;

        @media (max-width: 1200px) {
            width: 400px;
        }
    }
`;

export const Title = styled.p`
    font-size: 48px;
    color: ${colors.color_FFFFFF};

    @media (max-width: 1200px) {
        font-size: 28px;
    }
`;

export const SubTitle = styled.p`
    font-size: 16px;
    color: ${colors.color_FFFFFF};

    @media (max-width: 1200px) {
        font-size: 14px;
    }
`;

export const ButtonsContent = styled.div`
    display: flex;
    width: 100%;
    column-gap: 20px;
`;
