import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import GlobalStyle from './styles/global';

import AppProvidder from './hooks';

import Routes from './routes';

const App: React.FC = () => {
    return (
        <div style={{ height: '100vh' }}>
            <Router>
                <AppProvidder>
                    <Routes />
                </AppProvidder>
                <GlobalStyle />
            </Router>
        </div>
    );
};

export default App;
