import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import {
    Container,
    Content,
    SubTitleContent,
    Title,
    UnderlinedTitle,
    InfoText,
    CardContent,
    YourOperationInThePalmOfYourHandContent,
    WomenImg,
    YourOperationInThePalmOfYourHandTitleContent,
    AutomationControlTitle,
    AutomationControlDescription,
    ValueCardPropertie,
    AboutImg,
    AboutImgContent,
    AboutTitle,
    AboutDescription,
    AboutCard,
    AdvantagesCardRow,
    AdvantagesCardColumn,
    AdvantagesCard,
    AdvantagesIconCard,
    AdvantagesTitleCard,
    AdvantagesDescriptionCard,
} from './styles';
import MaloteIcon from '../../assets/svgs/MaloteIcon.svg';
import DeliveryIcon from '../../assets/svgs/DeliveryIcon.svg';
import MensageriaIcon from '../../assets/svgs/MensageriaIcon.svg';
import WomenIcon from '../../assets/svgs/Women.svg';
import WomenFooterIcon from '../../assets/svgs/WomenFooter.svg';
import AboutIcon from '../../assets/svgs/About.svg';
import TicketIcon from '../../assets/svgs/TicketIcon.svg';
import TrackingIcon from '../../assets/svgs/TrackingIcon.svg';
import DocIcon from '../../assets/svgs/DocIcon.svg';
import PlusIcon from '../../assets/svgs/PlusIcon.svg';
import UpArrowIcon from '../../assets/svgs/UpArrowIcon.svg';
import HamburgerMenuIcon from '../../assets/svgs/HamburgerMenuIcon.svg';
import ServiceCard from '../../components/ServiceCard';
import Button from '../../components/Button';
import ValueCard from '../../components/ValueCard';
import Tracking from './Tracking';

const Home: React.FC = () => {
    return (
        <Container>
            <Header />
            <Content>
                <Title>Nossos serviços</Title>
                <UnderlinedTitle />
                <SubTitleContent>
                    <InfoText style={{ marginTop: '24px', marginBottom: '30px' }}>
                        Conheça um pouco mais sobre os nossos serviços, fazemos
                        controle e gerenciamento de todas as suas entregas, via
                        Malote, Delivery e Mensageria Interna
                    </InfoText>
                </SubTitleContent>
                <CardContent>
                    <ServiceCard
                        title="Controle de Malotes"
                        icon={MaloteIcon}
                        description="Tenha o controle de todos os itens enviados por malotes e suas vias"
                    />
                    <ServiceCard
                        title="Controle de Entregas"
                        icon={DeliveryIcon}
                        description="Suas entregas mais precisas, com controles de saída e chegada."
                    />
                    <ServiceCard
                        title="Controle de Documentos"
                        icon={MensageriaIcon}
                        description="Chega de perder aquele documento e não saber onde ele está"
                    />
                </CardContent>
                <Tracking />
                <YourOperationInThePalmOfYourHandContent>
                    <WomenImg src={WomenIcon} />
                    <YourOperationInThePalmOfYourHandTitleContent>
                        <Title>Sua operação na palma da mão</Title>
                        <InfoText>
                            Controle, organize e tenha toda a informação sobre o
                            contexto operacional da sua empresa referente as entregas
                            por malote, delivery e mensagerias internas no seu
                            smartphone.
                        </InfoText>
                        <InfoText>
                            Sempre perto e ágil para que o controle esteja sempre em
                            sua mão.
                        </InfoText>
                        <Button style={{ marginTop: '25px' }}>SAIBA MAIS</Button>
                    </YourOperationInThePalmOfYourHandTitleContent>
                </YourOperationInThePalmOfYourHandContent>
                <div
                    style={{
                        backgroundImage: `url('${WomenFooterIcon}')`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: '280px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <AutomationControlTitle>
                        Controle automatizado
                    </AutomationControlTitle>
                    <AutomationControlDescription>
                        Controle sua operação de onde estiver na palma da sua mão ou
                        do seu escritório.
                    </AutomationControlDescription>
                    <AutomationControlDescription>
                        Fazemos a gestão de todos os seus documentos enviados,
                        trazendo rastreabilidade e controle para todos os itens
                    </AutomationControlDescription>
                </div>
                <Title style={{ marginTop: '50px' }}>
                    Selecione um dos nossos planos
                </Title>
                <UnderlinedTitle />
                <InfoText style={{ marginTop: '24px' }}>
                    Estamos sempre dispostos a atende-los, criamos especialmente para
                    você varias modalidades.
                </InfoText>
                <InfoText style={{ marginBottom: '30px' }}>
                    Trazendo flexibilidade e controle para sua operação, reduzindo
                    custos e otimizando suas entregas
                </InfoText>
                <CardContent>
                    <ValueCard title="BASIC" value="$15.00" type="light">
                        <ValueCardPropertie>Aplicativo Android</ValueCardPropertie>
                        <ValueCardPropertie>Aplicativo iOS</ValueCardPropertie>
                        <ValueCardPropertie>Sistema Web</ValueCardPropertie>
                        <ValueCardPropertie>
                            Controle de Malotes 4 vias
                        </ValueCardPropertie>
                        <ValueCardPropertie>500 Etiquetas</ValueCardPropertie>
                        <ValueCardPropertie>4 usuários</ValueCardPropertie>
                        <Button
                            style={{
                                marginTop: '20px',
                                width: '185px',
                                height: '46px',
                            }}
                        >
                            SELECIONAR
                        </Button>
                    </ValueCard>
                    <ValueCard title="STANDERD" value="$49.00">
                        <ValueCardPropertie>Aplicativo Android</ValueCardPropertie>
                        <ValueCardPropertie>Aplicativo iOS</ValueCardPropertie>
                        <ValueCardPropertie>Sistema Web</ValueCardPropertie>
                        <ValueCardPropertie>
                            Controle de Malotes 10 vias
                        </ValueCardPropertie>
                        <ValueCardPropertie>Controle de Entregas</ValueCardPropertie>
                        <ValueCardPropertie>1000 Etiquetas</ValueCardPropertie>
                        <Button
                            style={{
                                marginTop: '20px',
                                width: '185px',
                                height: '46px',
                            }}
                        >
                            SELECIONAR
                        </Button>
                    </ValueCard>
                    <ValueCard title="PREMIUM" value="$120.00" type="light">
                        <ValueCardPropertie>Aplicativo Android</ValueCardPropertie>
                        <ValueCardPropertie>Aplicativo iOS</ValueCardPropertie>
                        <ValueCardPropertie>Sistema Web</ValueCardPropertie>
                        <ValueCardPropertie>
                            Controle de Malotes 10 vias
                        </ValueCardPropertie>
                        <ValueCardPropertie>
                            Controle de Entregas e Mensagerias
                        </ValueCardPropertie>
                        <ValueCardPropertie>5000 Etiquetas</ValueCardPropertie>
                        <Button
                            style={{
                                marginTop: '20px',
                                width: '185px',
                                height: '46px',
                            }}
                        >
                            SELECIONAR
                        </Button>
                    </ValueCard>
                </CardContent>
                <Title style={{ marginTop: '50px' }}>
                    Conheça os nossos serviços
                </Title>
                <UnderlinedTitle />
                <InfoText style={{ marginTop: '24px' }}>
                    Nossos serviços são baseados nas maiores e melhores tecnologias
                    do mercado.
                </InfoText>
                <InfoText
                    style={{
                        marginBottom: '30px',
                        maxWidth: '1000px',
                        textAlign: 'center',
                    }}
                >
                    Com o gerenciamento e controle automatizado através da nossa
                    plataforma web e aplicativo mobile, conseguimos transformar
                    operações em mais informatizadas e sistematizadas.
                </InfoText>
                <AboutImgContent>
                    <AboutImg src={AboutIcon} />
                    <div
                        style={{
                            marginLeft: '110px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <AboutTitle>Malote</AboutTitle>
                        <AboutDescription
                            style={{ marginTop: '16px', maxWidth: '528px' }}
                        >
                            Com o serviço de malote conseguimos trazer mais
                            rastreabilidade para todos os seus documentos enviados,
                            nada de perder aquele documento e agora você sabe
                            exatamente onde o mesmo está e a quanto tempo falta para
                            chegar até você.
                        </AboutDescription>
                    </div>
                </AboutImgContent>
                <div
                    style={{
                        marginTop: '20px',
                        marginLeft: '110px',
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '24px',
                    }}
                >
                    <AboutCard>
                        <AboutTitle>Entrega/Delivery</AboutTitle>
                        <AboutDescription>
                            Com o módulo de entrega, você conseguirá entender e ficar
                            por dentro de todas as suas entregas, nada de perder a
                            chegada de uma compra ou não ter informações precisas
                            sobre onde o item que comprou se encontra. Mais autonomia
                            nas suas compras e controle de suas entregas.
                        </AboutDescription>
                    </AboutCard>
                    <AboutCard>
                        <AboutTitle>Mensageria</AboutTitle>
                        <AboutDescription>
                            Aqueles documentos que transitam internamente dentro da
                            sua empresa, precisam de controle e de mais segurança,
                            então o Mande Seguro pensou nisso também, rastreamento e
                            confirmações de recebimento e entrega a cada documento
                            que for gerenciado. Nada de perder aquele contrato
                            valioso.
                        </AboutDescription>
                    </AboutCard>
                </div>
                <AdvantagesCardRow>
                    <AdvantagesCardColumn style={{ marginTop: '74px' }}>
                        <AdvantagesCard>
                            <AdvantagesIconCard src={TicketIcon} />
                            <AdvantagesTitleCard>Tag</AdvantagesTitleCard>
                            <AdvantagesDescriptionCard>
                                Tags customizadas para controle e rastreamento dos
                                seus documentos.
                            </AdvantagesDescriptionCard>
                        </AdvantagesCard>
                        <AdvantagesCard>
                            <AdvantagesIconCard src={TrackingIcon} />
                            <AdvantagesTitleCard>Rastreamento</AdvantagesTitleCard>
                            <AdvantagesDescriptionCard>
                                Rastreamento de todos os documentos que estão sendo
                                controlados.
                            </AdvantagesDescriptionCard>
                        </AdvantagesCard>
                    </AdvantagesCardColumn>
                    <AdvantagesCardColumn>
                        <AdvantagesCard>
                            <AdvantagesIconCard src={DocIcon} />
                            <AdvantagesTitleCard>Gerenciamento</AdvantagesTitleCard>
                            <AdvantagesDescriptionCard>
                                Controle de documentos através da plataforma web
                                Mande Seguro.
                            </AdvantagesDescriptionCard>
                        </AdvantagesCard>
                        <AdvantagesCard active={true}>
                            <AdvantagesIconCard src={PlusIcon} />
                            <AdvantagesTitleCard active={true}>
                                Produtividade
                            </AdvantagesTitleCard>
                            <AdvantagesDescriptionCard active={true}>
                                Produtividade para todos controle de todos os
                                documentos e segurança.
                            </AdvantagesDescriptionCard>
                        </AdvantagesCard>
                    </AdvantagesCardColumn>
                    <AdvantagesCardColumn style={{ marginTop: '74px' }}>
                        <AdvantagesCard>
                            <AdvantagesIconCard src={UpArrowIcon} />
                            <AdvantagesTitleCard>
                                Gestão e Controle
                            </AdvantagesTitleCard>
                            <AdvantagesDescriptionCard>
                                Gerenciamento e controle das operações de Malote,
                                Entregas e Mensageria.
                            </AdvantagesDescriptionCard>
                        </AdvantagesCard>
                        <AdvantagesCard>
                            <AdvantagesIconCard src={HamburgerMenuIcon} />
                            <AdvantagesTitleCard>Automatização</AdvantagesTitleCard>
                            <AdvantagesDescriptionCard>
                                Operações automatizadas e informatizadas.
                            </AdvantagesDescriptionCard>
                        </AdvantagesCard>
                    </AdvantagesCardColumn>
                </AdvantagesCardRow>
                <Title style={{ marginTop: '100px' }}>
                    Venha conhecer ainda mais
                </Title>
                <UnderlinedTitle />
                <InfoText style={{ marginTop: '24px' }}>
                    Estamos disponíveis para atende-los. Fale com um de nossos
                    consultores
                </InfoText>
                <Button style={{ marginTop: '40px', marginBottom: '60px' }}>
                    CONTRATE JÁ
                </Button>
                <Footer />
            </Content>
        </Container>
    );
};

export default Home;
