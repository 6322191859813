import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: 0;
    outline: 0;
  }

  body {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    color: #021744;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button  {
    font-family: 'Poppins', 'Roboto Slab', serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }
`;
