import styled from 'styled-components';
import colors from '../../../../styles/colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    width: 389px;
    height: 48px;
    border: 1px solid ${colors.color_3F577B};
    border-radius: 4px;
`;

export const InputContainer = styled.input`
    padding: 0 20px;
    width: 100%;
    height: 100%;
    font-size: 13px;
    background-color: transparent;
    border: none;
    color: ${colors.color_3F577B};

    ::placeholder {
        color: ${colors.color_3F577B};
    }
`;

export const SearchButton = styled.button`
    background-color: transparent;
    border: none;
    margin-right: 20px;
`;

export const Label = styled.p`
    color: ${colors.color_3F577B};
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
`;
