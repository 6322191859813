import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
    min-width: 1000px;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;

export const Title = styled.p`
    font-size: 48px;
    color: ${colors.color_3F577B};
    font-weight: bold;
`;

export const UnderlinedTitle = styled.div`
    width: 150px;
    height: 3px;
    background-color: ${colors.color_4BB493};
`;

export const SubTitleContent = styled.div`
    max-width: 708px;
    display: flex;
    text-align: center;
`;

export const InfoText = styled.p`
    font-size: 16px;
    color: ${colors.color_838FAE};
`;

export const InfoTextBold = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: ${colors.color_3F577B};
    margin-bottom: 30px;
`;

export const CardContent = styled.div`
    display: flex;
    justify-content: center;
    gap: 45px;
`;

export const YourOperationInThePalmOfYourHandContent = styled.div`
    margin-top: 50px;
    display: flex;
    align-items: center;
    padding: 0 100px;
    width: calc(100% - 2 * 100px);
    height: 380px;
    background-color: ${colors.color_F9FBFF};
`;

export const YourOperationInThePalmOfYourHandTitleContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const WomenImg = styled.img`
    margin-top: 60px;
    height: 400px;
    z-index: 1;
`;

export const AutomationControlTitle = styled.p`
    font-size: 40px;
    font-weight: bold;
    color: ${colors.color_FFFFFF};
    opacity: 0.48;
    margin-bottom: 25px;
`;

export const AutomationControlDescription = styled.p`
    font-size: 16px;
    color: ${colors.color_FFFFFF};
`;

export const ValueCardPropertie = styled.p`
    margin-top: 16px;
    font-size: 16px;
    color: ${colors.color_44537E};
`;

export const AboutImgContent = styled.div`
    display: flex;
`;

export const AboutImg = styled.img`
    height: 420px;
`;

export const AboutCard = styled.div`
    padding: 40px 35px;
    width: calc(553px - 2 * 35px);
    height: calc(394px - 2 * 40px);
    box-shadow: 0px 3px 6px #00000029;
`;

export const AboutTitle = styled.p`
    font-size: 25px;
    font-weight: 500;
    color: ${colors.color_3F577B};
`;

export const AboutDescription = styled.p`
    margin-top: 19px;
    font-size: 20px;
    color: ${colors.color_8F90AE};
`;

export const AdvantagesCardRow = styled.div`
    margin-top: 65px;
    display: flex;
    gap: 74px;
`;

export const AdvantagesCardColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 74px;
`;

export const AdvantagesCard = styled.div<{ active?: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 65px 33px;
    width: calc(296px - 2 * 33px);
    height: calc(345px - 2 * 65px);
    box-shadow: 20px 22px 24px #29276421;
    border-radius: 15px;

    ${({ active }) =>
        active &&
        css`
            background-color: rgba(63, 87, 123, 0.71);
        `}
`;

export const AdvantagesIconCard = styled.img`
    width: 55px;
    height: 55px;
`;

export const AdvantagesTitleCard = styled.p<{ active?: boolean }>`
    margin-top: 32px;
    font-weight: 600;
    font-size: 24px;
    color: ${colors.color_8F90AE};

    ${({ active }) =>
        active &&
        css`
            color: ${colors.color_FFFFFF};
        `}
`;

export const AdvantagesDescriptionCard = styled.p<{ active?: boolean }>`
    margin-top: 28px;
    font-weight: 300;
    font-size: 16px;
    color: ${colors.color_B7B7BC};

    ${({ active }) =>
        active &&
        css`
            color: ${colors.color_F5F4F4};
        `}
`;
