import React, { InputHTMLAttributes, CSSProperties, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { FiSearch } from 'react-icons/fi';
import { Container, InputContainer, SearchButton } from './styles';
import colors from '../../styles/colors';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;

    containerStyle?: CSSProperties;
}

const Input: React.FC<InputProps> = ({ name, containerStyle, ...rest }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldName, defaultValue, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <Container style={{ ...containerStyle }}>
            <SearchButton>
                <FiSearch color={`${colors.color_FFFFFF}`} size={15} />
            </SearchButton>
            <InputContainer defaultValue={defaultValue} ref={inputRef} {...rest} />
        </Container>
    );
};

export default Input;
