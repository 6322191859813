import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    border: 2px solid ${colors.color_FFFFFF};
`;

export const InputContainer = styled.input`
    padding: 0 20px;
    width: 100%;
    height: 100%;
    font-size: 13px;
    background-color: transparent;
    border: none;
    color: ${colors.color_FFFFFF};

    ::placeholder {
        color: ${colors.color_FFFFFF};
    }
`;

export const SearchButton = styled.button`
    background-color: transparent;
    border: none;
    margin-left: 20px;
`;
